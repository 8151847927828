<template>
  <div class="home">
    <div class="home-hero">
      <div class="home-hero__inner">
        <div class="home-hero__left">
          <h1 class="home-hero__heading">
            A tool for<br />
            teachers
          </h1>
          <div class="home-hero__description">
            Quickly and discreetly pick students at random and keep the whole
            class engaged and motivated.
          </div>
          <div class="home-hero__platform-info">
            <div class="home-hero__platform-availability">
              <div class="home-hero__platform-availability-primary">
                Free for iPhone & iPad
              </div>
              <!-- <div class="home-hero__platform-availability-secondary">
                Coming soon for iPad
              </div> -->
            </div>
            <div class="home-hero__app-store-links">
              <a
                class="home-hero__app-store-link"
                href="https://apps.apple.com/app/lolly-sticks/id1605567481"
              >
                <img
                  class="home-hero__app-store-badge"
                  src="https://res.cloudinary.com/dv5har4fh/image/upload/v1600093794/StickerCode/apple-app-store-badge.png"
                  width="499"
                  height="166"
                  alt="Apple AppStore badge"
                />
              </a>
              <!-- <a class="home-hero__app-store-link" href="" @click="comingSoon">
              <img
                class="home-hero__app-store-badge"
                src="https://res.cloudinary.com/dv5har4fh/image/upload/v1600093846/StickerCode/google-play-store-badge.png"
                width="562"
                height="166"
                alt="Google Play badge"
              />
            </a> -->
            </div>
          </div>
        </div>
        <div class="home-hero__right">
          <div class="home-hero__illustration">
            <picking-pot-illustration />
          </div>
        </div>
      </div>
    </div>
    <!-- Testimonials -->
    <div class="home__testimonials">
      <div class="home__testimonials-inner">
        <div class="home__testimonials-grid">
          <div class="home__testimonial">
            <div class="home__testimonial-image-wrapper">
              <div class="home__testimonial-image"></div>
            </div>
            <h4 class="home__testimonial-quote">
              "Lolly Sticks is my favourite app to use in the classroom."
            </h4>
            <div class="home__testimonial-citation">
              <div class="home__testimonial-author">Alex Placeholder</div>
              <div class="home__testimonial-job-title">
                Primary School Teacher
              </div>
            </div>
          </div>
          <div class="home__testimonial">
            <div class="home__testimonial-image-wrapper">
              <div class="home__testimonial-image"></div>
            </div>
            <h4 class="home__testimonial-quote">
              "My assessment for learning has improved since I started using
              Lolly Sticks."
            </h4>
            <div class="home__testimonial-citation">
              <div class="home__testimonial-author">Alex Placeholder</div>
              <div class="home__testimonial-job-title">
                Secondary School Teacher
              </div>
            </div>
          </div>
          <div class="home__testimonial">
            <div class="home__testimonial-image-wrapper">
              <div class="home__testimonial-image"></div>
            </div>
            <h4 class="home__testimonial-quote">
              "Using Lolly Sticks gets all the children thinking when I ask a
              question."
            </h4>
            <div class="home__testimonial-citation">
              <div class="home__testimonial-author">Alex Placeholder</div>
              <div class="home__testimonial-job-title">
                Infant School Teacher
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Features -->
    <div class="home__features">
      <div class="home__features-inner">
        <div class="home__features-grid">
          <div class="home__feature">
            <div class="home__feature-image-wrapper">
              <img
                class="home__feature-image"
                src="https://res.cloudinary.com/dv5har4fh/image/upload/v1662727890/Lolly%20Sticks/organise.png"
                alt="A set of labelled pots"
              />
            </div>
            <h4 class="home__feature-heading">
              Organise students into classes
            </h4>
            <div class="home__feature-description">
              Use different colours to make classes easy to distinguish.
            </div>
          </div>
          <div class="home__feature">
            <div class="home__feature-image-wrapper">
              <img
                class="home__feature-image"
                src="https://res.cloudinary.com/dv5har4fh/image/upload/v1662727890/Lolly%20Sticks/random.png"
                alt="A picked lolly stick and icons for pick, peek and reset"
              />
            </div>
            <h4 class="home__feature-heading">Pick students at random</h4>
            <div class="home__feature-description">
              Peek inside the pot or reset it if you want to start over.
            </div>
          </div>
          <div class="home__feature">
            <div class="home__feature-image-wrapper">
              <img
                class="home__feature-image"
                src="https://res.cloudinary.com/dv5har4fh/image/upload/v1662727890/Lolly%20Sticks/edit.png"
                alt="Icons for adding, editing and removing a student"
              />
            </div>
            <h4 class="home__feature-heading">
              Quickly and easily edit classes
            </h4>
            <div class="home__feature-description">
              Add, edit and remove students with ease.
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PickingPotIllustration from "@/components/PickingPotIllustration";

export default {
  name: "ViewHome",
  components: {
    PickingPotIllustration,
  },
};
</script>

<style>
.home-hero {
  background: var(--base-background);
  overflow: hidden;
  /* border: 1px solid red; */
}
.home-hero__inner {
  max-width: 64rem;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  overflow-x: initial;
  /* border: 1px solid red; */
}
.home-hero__left {
  padding: 1rem 0 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  /* border: 1px solid red; */
}
.home-hero__heading {
  margin: 0.5rem 1rem;
  font-size: 2.5rem;
  font-weight: 700;
  line-height: 1.125;
  /* letter-spacing: -0.05rem; */
  text-align: center;
  color: var(--base-text-color);
}
.home-hero__description {
  max-width: 32rem;
  margin: 0.5rem 2rem;
  font-size: 1.125rem;
  font-weight: 300;
  line-height: 1.5;
  text-align: center;
  color: var(--base-text-color);
}
.home-hero__platform-info {
  width: 100%;
  max-width: 20.5rem;
  margin: 0.5rem 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* border: 1px solid red; */
}
.home-hero__platform-availability {
  font-size: 1rem;
  white-space: pre;
  color: var(--base-text-color);
}
.home-hero__platform-availability-primary {
  font-weight: 800;
}
.home-hero__platform-availability-secondary {
  font-weight: 600;
}
.home-hero__app-store-links {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.home-hero__app-store-link:first-child {
  margin-right: 0.5rem;
}
.home-hero__app-store-badge {
  width: auto;
  height: 2.75rem;
}

.home-hero__right {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.home-hero__illustration {
  margin: 1.5rem 0;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: scale(1);
}

@media screen and (min-width: 720px) {
  .home-hero__heading {
    margin: 1.5rem 1.5rem;
    font-size: 3.5rem;
  }
  .home-hero__description {
    margin: 1.5rem 0;
    font-size: 1.5rem;
  }
  .home-hero__platform-info {
    margin: 1.5rem 1.5rem;
  }
  .home-hero__right {
  }
  .home-hero__illustration {
    margin: 2.5rem 0;
    transform: scale(1.25);
  }
}

@media screen and (min-width: 960px) {
  .home-hero__inner {
    padding: 5rem 4rem 2rem;
    flex-direction: row;
    justify-content: flex-start;
  }
  .home-hero__left {
    width: 50%;
    align-items: flex-start;
  }
  .home-hero__heading {
    margin: 1.5rem 0;
    font-size: 4rem;
  }
  .home-hero__description {
    margin: 2rem 0;
    font-size: 1.75rem;
  }
  .home-hero__heading,
  .home-hero__description {
    text-align: left;
  }
  .home-hero__platform-info {
    margin: 1.5rem 0;
  }
  .home-hero__right {
    width: 50%;
  }
  .home-hero__illustration {
    transform: scale(1.5);
  }
}

/*
Testimonials
*/
.home__testimonials {
  background: var(--base-background);
}
.home__testimonials-inner {
  max-width: 24rem;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.home__testimonials-grid {
  padding: 0rem 0rem 1rem;
  display: grid;
  grid-template-columns: 1fr;
  gap: 1rem;
}
.home__testimonial {
  padding: 0 1.5rem;
}
.home__testimonial-image-wrapper {
  margin: 0.75rem 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.home__testimonial-image {
  width: 80px;
  height: 80px;
  background: var(--secondary-background);
  border: var(--base-border);
  border-radius: 50%;
}
.home__testimonial-quote {
  margin: 0;
  font-size: 1.125rem;
  font-weight: 300;
  color: var(--secondary-text-color);
}
.home__testimonial-citation {
  margin: 0.75rem 0;
  font-size: 1rem;
}
.home__testimonial-author {
  font-weight: 600;
  color: var(--base-text-color);
}
.home__testimonial-job-title {
  font-weight: 300;
  color: var(--secondary-text-color);
}
@media screen and (min-width: 720px) {
  .home__testimonials-inner {
    max-width: 32rem;
  }
  .home__testimonials-grid {
    padding: 3rem 0rem;
  }
  .home__testimonial {
    padding: 0rem 2rem;
  }
  .home__testimonial-image-wrapper {
    margin: 1.25rem 0 1.5rem;
  }
  .home__testimonial-image {
    width: 96px;
    height: 96px;
  }
  .home__testimonial-quote {
    margin: 0;
    font-size: 1.5rem;
  }
  .home__testimonial-citation {
    margin: 1rem 0;
    font-size: 1.25rem;
  }
}
@media screen and (min-width: 960px) {
  .home__testimonials-inner {
    max-width: 64rem;
    text-align: left;
  }
  .home__testimonials-grid {
    padding: 0 2rem 2rem;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 4rem;
  }
  .home__testimonial {
    padding: 1.75rem 0;
  }
  .home__testimonial-image-wrapper {
    margin: 1.5rem 0 2rem;
  }
  .home__testimonial-image {
    width: 128px;
    height: 128px;
  }
  .home__testimonial-quote {
    margin: 0 0 0.5rem;
    font-size: 1.375rem;
  }
  .home__testimonial-citation {
    margin: 1.5rem 0;
    font-size: 1.25rem;
  }
}

/*
Features
*/
.home__features {
  background: var(--inverted-background);
}
.home__features-inner {
  max-width: 24rem;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.home__features-grid {
  padding: 1.5rem 0rem;
  display: grid;
  grid-template-columns: 1fr;
  gap: 1rem;
  /* grid-template-columns: 1fr 1fr; */
  /* gap: 3rem 2rem; */
}
.home__feature {
  padding: 1rem 2rem;
}
.home__feature-image-wrapper {
  margin-bottom: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.home__feature-image {
  width: 249px;
  height: 210px;
}
.home__feature-heading {
  margin: 0;
  font-size: 1.125rem;
  font-weight: 700;
  color: var(--inverted-text-color);
}
.home__feature-description {
  margin: 0;
  font-size: 1rem;
  font-weight: 400;
  color: var(--secondary-background);
}
@media screen and (min-width: 720px) {
  .home__features-inner {
    max-width: 64rem;
  }
  .home__feature {
    padding: 1.75rem 2rem;
  }
  .home__feature-image-wrapper {
    margin-bottom: 1.75rem;
  }
  .home__feature-image {
  }
  .home__feature-heading {
    margin: 0;
    font-size: 1.25rem;
  }
  .home__feature-description {
    margin: 0;
    font-size: 1.125rem;
  }
}
@media screen and (min-width: 960px) {
  .home__features-inner {
    max-width: 64rem;
    text-align: left;
  }
  .home__features-grid {
    padding: 3rem 2rem;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 4rem;
  }
  .home__feature {
    padding: 1.75rem 0;
  }
  .home__feature-image-wrapper {
    margin-bottom: 2rem;
  }
  .home__feature-image {
  }
  .home__feature-heading {
    margin: 0 0 0.5rem;
    font-size: 1.125rem;
  }
  .home__feature-description {
    margin: 0;
    font-size: 1.125rem;
  }
}
</style>
