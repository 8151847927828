<template>
  <header class="app__header">
    <nav class="app__primary-nav" role="navigation">
      <router-link class="app__home-link" to="/">
        <img
          class="app__logo"
          src="https://res.cloudinary.com/dv5har4fh/image/upload/v1662718128/Lolly%20Sticks/logo-lockup.png"
          alt="Lolly Sticks logo"
        />
      </router-link>
      <ul class="app__menu">
        <li class="app__menu-item">
          <router-link class="app__menu-list-item" to="/">Home</router-link>
        </li>
        <li class="app__menu-item">
          <router-link class="app__menu-list-item" to="/support"
            >Support</router-link
          >
        </li>
      </ul>
    </nav>
  </header>
  <router-view />
  <footer class="footer">
    <div class="footer__inner">
      <div class="footer__copyright-notice">&copy; 2022 Clever Crow</div>
      <nav class="footer__nav">
        <div class="footer__nav-group">
          <div class="footer__nav-group-header">Download</div>
          <ul class="footer__nav-group-list">
            <li class="footer__nav-group-list-item">
              <a
                class="footer__nav-group-link"
                href="https://apps.apple.com/app/lolly-sticks/id1605567481"
                >iPhone & iPad</a
              >
            </li>
            <!-- <li class="footer__nav-group-list-item">
              <a class="footer__nav-group-link" href="">Android</a>
            </li> -->
          </ul>
        </div>
        <div class="footer__nav-group">
          <div class="footer__nav-group-header">Social</div>
          <ul class="footer__nav-group-list">
            <li class="footer__nav-group-list-item">
              <a
                class="footer__nav-group-link"
                href="https://twitter.com/lollysticks_app"
                >Twitter</a
              >
            </li>
            <li class="footer__nav-group-list-item">
              <a
                class="footer__nav-group-link"
                href="https://www.instagram.com/lollysticksapp/"
                >Instagram</a
              >
            </li>
          </ul>
        </div>
        <div class="footer__nav-group">
          <div class="footer__nav-group-header">Legal</div>
          <ul class="footer__nav-group-list">
            <li class="footer__nav-group-list-item">
              <router-link class="footer__nav-group-link" to="/privacy"
                >Privacy</router-link
              >
            </li>
            <!-- <li class="footer__nav-group-list-item">
              <router-link class="footer__nav-group-link" to="/terms"
                >Terms</router-link
              >
            </li> -->
          </ul>
        </div>
        <div class="footer__nav-group">
          <div class="footer__nav-group-header">Support</div>
          <ul class="footer__nav-group-list">
            <li class="footer__nav-group-list-item">
              <router-link class="footer__nav-group-link" to="/support"
                >FAQs</router-link
              >
            </li>
            <li class="footer__nav-group-list-item">
              <router-link class="footer__nav-group-link" to="/support/#contact"
                >Contact</router-link
              >
            </li>
          </ul>
        </div>
      </nav>
    </div>
  </footer>
</template>

<style>
/* Import Poppins in regular 400, medium 500, semi-bold 600, bold 700 */
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap");

* {
  box-sizing: border-box;
}

:root {
  --base-font-family: "Poppins", "Helvetica", "Arial", sans-serif;
  --base-font-size: 100%;
  --base-font-weight: 400;
  --base-line-height: 1.5;
  --grey-90: #31323a;
  --grey-50: #8b8e9e;
  --grey-40: #b8bac1;
  --grey-10: #f1f2f3;
  --brand-green: #94c407;
  --brand-teal: #1cb59b;
  --brand-blue: #14a8f8;
  --brand-purple: #7668d3;
  --brand-pink: #ef60a4;
  --brand-red: #ec4949;
  --brand-orange: #ff7d0d;
  --brand-yellow: #ffc002;
  --brand-grey: #4a4b65;
  --base-overlay-color: rgba(0, 0, 0, 0.5);
  --popover-background: var(--brand-primary-40);
  --popover-text-color: #202127;
  --danger-background: #f95959;
  --danger-text-color: #fff;
}

/* Light mode */
:root {
  --base-background: #fff;
  --secondary-background: #f1f2f3;
  --inverted-background: #202127;
  --base-icon-color: #202127;
  --base-text-color: #202127;
  --secondary-text-color: #74778a;
  --inverted-text-color: #f1f2f3;
  --base-link-text-color: #ec4949;
  --base-border: 1px solid #dedfe3;
  --base-placeholder-color: #b8bac1;
  --success-background: #202127;
  --success-text-color: #fff;
}

body {
  margin: 0;
  background: var(--secondary-background);
  color: var(--base-text-color);
  font-family: var(--base-font-family);
  font-size: var(--base-font-size);
  font-weight: var(--base-font-weight);
  line-height: var(--base-line-height);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* Prevent background from scrolling when a modal view is open */
body.--modal-is-open {
  overflow: hidden !important;
}

*::placeholder {
  color: var(--base-placeholder-color);
}

/* Remove tap highlight on iOS */
input,
textarea,
button,
select,
label,
a {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.app__header {
  height: 4rem;
  padding: 0 1rem 0 0.25rem;
  background-color: var(--secondary-background);
  border-bottom: var(--base-border);
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.app__primary-nav {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.app__home-link {
  display: flex;
}
.app__logo {
  width: 131px;
  height: 56px;
}
.app__menu {
  height: 5rem;
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.app__menu-item {
  margin-left: 1.5rem;
  /* border: 1px solid red; */
}
.app__menu-list-item {
  padding: 0.25rem 0;
  font-size: 1.125rem;
  font-weight: 600;
  text-decoration: none;
  color: var(--base-text-color);
  display: flex;
  justify-content: center;
  align-items: center;
}
.app__menu-list-item.router-link-exact-active {
  color: var(--secondary-text-color);
}
/* Footer */
.footer {
  background: var(--secondary-background);
  border-top: var(--base-border);
  font-size: 1rem;
  /* border: 1px solid red; */
}
.footer__inner {
  margin: 0 auto;
  padding: 1.5rem 1rem;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.footer__copyright-notice {
  color: var(--secondary-text-color);
  display: flex;
}
.footer__nav {
  display: grid;
  grid-template-columns: 1fr;
  gap: 1rem;
}
.footer__nav-group {
  min-width: 8rem;
  margin-bottom: 1rem;
  display: flex;
  flex-direction: column;
}
.footer__nav-group-header {
  margin: 0 0 0.5rem;
  padding: 0;
  font-weight: 700;
  color: var(--secondary-text-color);
}
.footer__nav-group-list {
  margin: 0;
  padding: 0;
  list-style: none;
}
.footer__nav-group-list-item {
  padding: 0.25rem 0;
  display: flex;
  flex-direction: column;
}
.footer__nav-group-link {
  text-decoration: none;
  color: var(--secondary-text-color);
}

@media screen and (min-width: 720px) {
  .app__header {
    height: 4rem;
    padding: 0 1.25rem;
  }
  .app__menu {
    margin: 0 2rem;
  }
  .app__menu-item {
    margin-left: 2rem;
  }
  .footer__inner {
    padding: 2rem 1.5rem;
  }
  .footer__nav {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 2rem;
  }
}
@media screen and (min-width: 960px) {
  .app__header {
    height: 5rem;
    padding: 0 2rem;
  }
  .app__menu {
    margin: 0 4rem;
  }
  .app__menu-item {
    margin-left: 2rem;
  }
  .footer__inner {
    max-width: 64rem;
    padding: 3rem 2rem;
  }
  .footer__nav {
    margin-right: 0;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 1rem;
  }
}
</style>
